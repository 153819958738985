// eslint-disable-next-line no-redeclare,no-unused-vars
function download(doc) {
  if (isPlainObject(doc) && doc.s3 && !doc.download)
    ajax({
      object: 'document',
      id: doc.id,
      send: {func: 'download'},
    });
  else {
    var address = '';
    if (!isPlainObject(doc)) address = doc;
    else if (doc.download) address = doc.download;
    else if (doc.file) address = doc.file;
    if (!strpos(address, '?')) address += '?download=1';
    window.open(address);
  }
}
